import { $ } from 'hanako-ts/dist-legacy/Framework';
import BS_Toast from 'bootstrap/js/dist/toast';
export class Toast {
    static init() {
        this.toastContainer = $('#toast-container');
    }
    static show(title, content, backgroundClass) {
        if (typeof content === 'string')
            content = $.parseHTML('<p class="m-0">' + content + '</p>');
        const toast = $('.toast.model').clone();
        toast.removeClass('model');
        toast.find('.toast-title').text(title);
        toast.find('.toast-body').append(content);
        if (backgroundClass)
            toast.addClass(backgroundClass);
        this.toastContainer.append(toast);
        const bs_toast = new BS_Toast(toast.get(0), {
            autohide: false
        });
        bs_toast.show();
    }
    static removeAll() {
        $('.toast:not(.model)').remove();
    }
}
