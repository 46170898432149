var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Timer } from './Timer';
import { View } from './View';
import { Inventory } from './Inventory';
import { Modal } from '../helpers/Modal';
import { Collection } from 'hanako-ts/dist-legacy/Collection';
import { Room } from './Room';
import { Toast } from '../helpers/Toast';
export class Game {
    static init(path, username) {
        this.path = '/games/' + path;
        this.username = username;
        $('#home').addClass('d-none');
        $('#game').removeClass('d-none');
        $('body').off('time-ended').on('time-ended', () => {
            this.trackAction('loose');
            Modal.show('Perdu', 'Vous avez perdu', Modal.createButton('Retour', 'primary', true, () => {
                this.stop();
            }), true);
        });
        $('#answer').off('keypress').on('keypress', (event) => {
            if (event.key === 'Enter')
                $('#btn-modal-validate').trigger('click');
        });
        this.inventory = new Inventory();
        this.timeSpentInRooms = {};
        $(window).on('resize', () => {
            this.resize();
        });
    }
    static start(isRestore = false) {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = yield $.httpRequest({
                url: this.path + '/config.json',
                type: 'GET',
                dataType: 'json',
            });
            this.roomsData = yield $.httpRequest({
                url: this.path + '/rooms.json',
                type: 'GET',
                dataType: 'json',
            });
            this.viewsData = yield $.httpRequest({
                url: this.path + '/views.json',
                type: 'GET',
                dataType: 'json',
            });
            this.itemsData = yield $.httpRequest({
                url: this.path + '/items.json',
                type: 'GET',
                dataType: 'json',
            });
            this.cluesData = yield $.httpRequest({
                url: this.path + '/clues.json',
                type: 'GET',
                dataType: 'json',
            });
            $('.navbar-brand').text(this.data.title);
            if (!isRestore) {
                this.setRoom(this.data.defaultRoom);
            }
            Inventory.setMaxItems(this.data.inventoryMaxItems);
            Timer.init(this.data.duration);
            this.saveIntervalID = window.setInterval(() => {
                this.save();
            }, 1000);
            this.resize();
            if (!isRestore)
                this.trackAction('start');
        });
    }
    static setRoom(roomID, viewID) {
        this.currentRoomID = roomID;
        this.currentRoomData = this.roomsData[this.currentRoomID];
        Room.init(this.currentRoomData, viewID);
        this.save();
    }
    static setView(viewID) {
        this.currentViewID = viewID;
        this.currentViewData = this.viewsData[this.currentViewID];
        View.draw(this.currentViewData);
        this.save();
    }
    static ending() {
        Timer.pause();
        this.trackAction('win');
        setTimeout(() => {
            const modalContent = new Collection();
            modalContent.add($.parseHTML('<div>' + this.currentRoomData.endMessage + '</div>').get(0));
            modalContent.add($.parseHTML('<p>Temps écoulé: ' + Timer.getElapsedTime() + '</p>').get(0));
            if (Game.data.rewardURL) {
                modalContent.add($.parseHTML('<a href="' + Game.data.rewardURL + '" target="_blank">Télécharger la récompense</a>').get(0));
            }
            Modal.show('Gagné', modalContent, Modal.createButton('Retour', 'primary', true, () => {
                this.stop();
            }), true);
        }, 3000);
    }
    static stop() {
        Modal.hide();
        Timer.pause();
        Timer.reset();
        Room.stopClueTimer();
        Inventory.clear();
        Toast.removeAll();
        this.sessionID = 0;
        clearInterval(this.saveIntervalID);
        sessionStorage.removeItem('gameID');
        sessionStorage.removeItem('savedata');
        $('#navbar-brand').empty();
        $('#view').empty().css('background-image', 'none');
        $('#home').removeClass('d-none');
        $('#game').addClass('d-none');
    }
    static resize() {
        const maxWidth = $(window).width();
        const maxHeight = $(window).height() - $('#inventory-container').height() - $('#game-menu').height();
        const windowRatio = maxWidth / maxHeight;
        $('#view').css({
            top: $('#game-menu').height(),
            width: windowRatio < this.data.imageRatio ? maxWidth : maxHeight * this.data.imageRatio,
            height: windowRatio < this.data.imageRatio ? maxWidth / this.data.imageRatio : maxHeight
        });
    }
    static load(saveData) {
        Timer.setElapsedTime(saveData.elapsedTime);
        saveData.inventory.forEach((item) => {
            Inventory.add(item, true);
        });
        this.sessionID = saveData.sessionID;
        this.timeSpentInRooms = saveData.timeSpentInRooms;
        this.setRoom(saveData.currentRoomID, saveData.currentViewID);
    }
    static save() {
        const saveData = {
            sessionID: this.sessionID,
            username: this.username,
            elapsedTime: Timer.getElapsedTime(true),
            currentRoomID: Game.currentRoomID,
            currentViewID: Game.currentViewID,
            inventory: Inventory.getItems(),
            timeSpentInRooms: Game.timeSpentInRooms
        };
        sessionStorage.setItem('savedata', JSON.stringify(saveData));
    }
    static trackAction(action, data = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield $.httpRequest({
                url: '/sessions/',
                type: 'POST',
                body: {
                    session_id: this.sessionID,
                    username: this.username,
                    time: Timer.getElapsedTime(),
                    action: action,
                    data: data
                },
                dataType: 'json',
            });
            if (action == 'start')
                this.sessionID = response;
        });
    }
}
Game.sessionID = 0;
