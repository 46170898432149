import { $ } from 'hanako-ts/dist-legacy/Framework';
import BS_Modal from 'bootstrap/js/dist/modal';
export class Modal {
    static init() {
        this.htmlElement = $('#modal');
        this.bsModal = new BS_Modal(this.htmlElement.get(0));
    }
    static show(title, content, buttons, replaceDefaultButtons) {
        // Manage title
        this.htmlElement.find('.modal-title').text(title);
        // Manage content
        if (typeof content === 'string')
            content = $.parseHTML('<p>' + content + '</p>');
        this.htmlElement.find('.modal-body').empty().append(content);
        // Manage buttons
        let modalButtons = this.createButton('Fermer', 'primary', true);
        if (replaceDefaultButtons && buttons) {
            modalButtons = buttons;
        }
        else if (buttons) {
            buttons.each((button) => {
                modalButtons.add(button.get(0));
            });
            modalButtons.eq(0).removeClass('btn-primary').addClass('btn-secondary');
        }
        this.htmlElement.find('.modal-footer').empty().append(modalButtons);
        // Focus question field if exist
        if (this.htmlElement.find('.modal-question').length > 0) {
            setTimeout(() => {
                $('.modal-question .answer').get(0).focus();
            }, 100);
        }
        // Display the modal
        this.bsModal.show();
    }
    static hide() {
        this.bsModal.hide();
    }
    static createButton(title, type, dismissModal, action) {
        if (!type)
            type = 'primary';
        if (!dismissModal)
            dismissModal = false;
        const button = $.parseHTML('<button type="button" class="btn btn-' + type + '" ' + (dismissModal ? 'data-bs-dismiss="modal"' : '') + '>' + title + '</button>');
        if (action)
            button.on('click', () => {
                action();
            });
        return button;
    }
    static createQuestion(placeholder) {
        const question = $.parseHTML('<div class="modal-question"><input type="text" class="form-control answer" placeholder="' + placeholder + '"></div>');
        question.on('keypress', (event) => {
            if (event.key == 'Enter')
                this.htmlElement.find('.btn-primary').trigger('click');
        });
        return question;
    }
}
