import { $ } from 'hanako-ts/dist-legacy/Framework';
import { ActionManager } from '../helpers/ActionManager';
import { Modal } from '../helpers/Modal';
import { Game } from './Game';
import { Item } from './Item';
import { View } from './View';
export class Inventory {
    static add(item, ignoreDetach = false) {
        if (this.items.length >= this.maxItems) {
            const s = this.maxItems > 1 ? 's' : '';
            Modal.show('Attention', 'Vous ne pouvez pas avoir plus de ' + this.maxItems + ' objet' + s + ' dans votre inventaire. Débarassez-vous d\'un objet pour en emporter un autre');
            return;
        }
        if (typeof item === 'string')
            item = new Item(Game.itemsData[item], false);
        this.items.push(item);
        if (!ignoreDetach)
            item.detache();
        this.updateHTML();
        Game.save();
    }
    static remove(id) {
        this.items = this.items.filter((item) => {
            return item.data.id !== id;
        });
        this.updateHTML();
        View.refresh();
        Game.save();
    }
    static has(id) {
        let has_item = false;
        this.items.forEach((item) => {
            if (item.data.id === id)
                has_item = true;
        });
        return has_item;
    }
    static getItems() {
        let itemsID = [];
        this.items.forEach((item) => {
            itemsID.push(item.data.id);
        });
        return itemsID;
    }
    static updateHTML() {
        $('#inventory').empty();
        this.items.forEach((item) => {
            const itemElement = $.parseHTML('<div class="inventory-item border" style="background-image:url(\'' + Game.path + '/' + item.data.inventoryImage + '\');"></div>');
            itemElement.on('click', () => {
                ActionManager.execute('click', item, true);
            });
            $('#inventory').append(itemElement);
        });
    }
    static clear() {
        this.items = [];
        this.updateHTML();
    }
    static setMaxItems(maxItems) {
        this.maxItems = maxItems;
    }
}
Inventory.items = [];
