var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Modal } from '../helpers/Modal';
import { Toast } from "../helpers/Toast";
import { Game } from "./Game";
import { Timer } from "./Timer";
export class Room {
    static init(roomData, viewID) {
        return __awaiter(this, void 0, void 0, function* () {
            if (roomData.type === 'page') {
                $('#view').empty();
                const pageHTML = yield $.httpRequest({
                    url: Game.path + '/' + roomData.page,
                    type: 'GET',
                    dataType: 'html',
                });
                $('#view').append(pageHTML);
            }
            else {
                Game.setView(viewID ? viewID : roomData.defaultView);
            }
            Game.timeSpentInRooms[roomData.id] = Game.timeSpentInRooms[roomData.id] ? Game.timeSpentInRooms[roomData.id] : 0;
            this.arrivalTime = Math.floor((new Date()).getTime() / 1000) - Game.timeSpentInRooms[roomData.id];
            this.stopClueTimer();
            this.cluesInterval = window.setInterval(() => {
                const currentTime = Math.floor((new Date()).getTime() / 1000);
                if (Array.isArray(roomData.clues)) {
                    roomData.clues.forEach((clueID) => {
                        const clue = Game.cluesData[clueID];
                        if (clue.after == currentTime - this.arrivalTime)
                            Toast.show('Indice', clue.text, clue.backgroundClass);
                    });
                }
                Game.timeSpentInRooms[roomData.id]++;
            }, 1000);
            if (['start', 'page'].indexOf(roomData.type) === -1)
                Timer.start();
            if (roomData.type == 'end')
                Game.ending();
            Toast.removeAll();
            if (roomData.welcomeText) {
                Modal.show('', roomData.welcomeText);
            }
        });
    }
    static stopClueTimer() {
        if (this.cluesInterval)
            clearInterval(this.cluesInterval);
    }
}
