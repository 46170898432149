import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Game } from "../classes/Game";
import { Modal } from './Modal';
import { Collection } from 'hanako-ts/dist-legacy/Collection';
import { Inventory } from '../classes/Inventory';
import { Room } from '../classes/Room';
export class ActionManager {
    static execute(event, item, fromInventory) {
        if (Array.isArray(item.data.actions)) {
            item.data.actions.forEach((action) => {
                if (action.event == event) {
                    // Manage navigation
                    if (action.action === 'navigate') {
                        ActionManager.navigate(action.target.room, action.target.view);
                        return;
                    }
                    // Manage modal content
                    let buttons = new Collection();
                    let modalContent = $.parseHTML('<div></div>');
                    if (action.text)
                        modalContent.append($.parseHTML('<p>' + action.text + '</p>'));
                    if (action.image)
                        modalContent.append($.parseHTML('<img src="' + Game.path + '/' + action.image + '" class="w-100" alt="">'));
                    if (action.url)
                        modalContent.append($.parseHTML('<a href="' + action.url + '" target="_blank">' + action.url + '</a>'));
                    // Manage question
                    if (action.action === 'question') {
                        modalContent.append(Modal.createQuestion(action.question.placeholder));
                        buttons.add(Modal.createButton('Valider', 'primary', false, () => {
                            const answer = $('.modal-question .answer').val();
                            if (this.isAnswerValid(answer, action.question.answer)) {
                                Modal.hide();
                                if (Array.isArray(action.items)) {
                                    action.items.forEach((itemID) => {
                                        Inventory.add(itemID);
                                    });
                                }
                                if (action.question.text) {
                                    if (action.target)
                                        Room.stopClueTimer();
                                    Modal.show('Bravo!', $.parseHTML('<div>' + action.question.text + '</div>'), Modal.createButton('Ok', 'primary', true, () => {
                                        if (action.target)
                                            ActionManager.navigate(action.target.room, action.target.view);
                                    }), true);
                                }
                                else {
                                    if (action.target)
                                        ActionManager.navigate(action.target.room, action.target.view);
                                }
                                Game.trackAction('answer', 'true,' + answer);
                            }
                            else {
                                Modal.show('Erreur', $.parseHTML('<p>La réponse est fausse...</p>'), Modal.createButton('Ok', 'primary', true, () => {
                                    ActionManager.execute(event, item, fromInventory);
                                }), true);
                                Game.trackAction('answer', 'false,' + answer);
                            }
                        }).get(0));
                    }
                    // Manage pickable objects
                    if (item.data.isPickable) {
                        if (!fromInventory) {
                            buttons.add(Modal.createButton('Ramasser', 'primary', true, () => {
                                Inventory.add(item);
                                Game.trackAction('pick', item.data.id + ',' + item.data.title);
                            }).get(0));
                        }
                        else {
                            buttons = new Collection();
                            buttons.add(Modal.createButton('Déposer', 'secondary', true, () => {
                                Inventory.remove(item.data.id);
                                Game.trackAction('drop', item.data.id + ',' + item.data.title);
                            }).get(0));
                            buttons.add(Modal.createButton('Fermer', 'primary', true).get(0));
                        }
                    }
                    // Create and display the modal
                    Modal.show(item.data.title, modalContent, buttons, fromInventory);
                    Game.trackAction(action.action, item.data.id + ',' + item.data.title);
                }
            });
        }
    }
    static navigate(roomID, viewID) {
        if (roomID != Game.currentRoomID) {
            Game.setRoom(roomID, viewID);
        }
        else {
            Game.setView(viewID);
        }
        Game.trackAction('navigate', roomID + ',' + viewID + ',' + Game.roomsData[roomID].title + ',' + Game.viewsData[viewID].title);
    }
    static isAnswerValid(answer, correctAnswer) {
        let isValid = false;
        if (Array.isArray(correctAnswer)) {
            correctAnswer.forEach(singleAnswer => {
                if (answer.toUpperCase() === singleAnswer.toUpperCase())
                    isValid = true;
            });
        }
        else {
            isValid = answer.toUpperCase() === correctAnswer.toUpperCase();
        }
        console.log(isValid);
        return isValid;
    }
}
