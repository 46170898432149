import { $ } from 'hanako-ts/dist-legacy/Framework';
import { ActionManager } from '../helpers/ActionManager';
import { Game } from './Game';
import { Inventory } from './Inventory';
export class Item {
    constructor(data, appendToView = true) {
        this.data = data;
        if (Inventory.has(this.data.id))
            return;
        if (appendToView) {
            this.itemElement = $.parseHTML('<div class="item"></div>');
            $('#view').append(this.itemElement);
            this.itemElement.css({
                left: this.data.x + '%',
                top: this.data.y + '%',
                width: this.data.width + '%',
                height: this.data.height + '%'
            });
            this.itemElement.addClass('item-' + this.getMainAction());
            if (this.data.backgroundImage)
                this.itemElement.css('background-image', 'url(\'' + Game.path + '/' + this.data.backgroundImage + '\')');
            this.itemElement.on('click', () => {
                ActionManager.execute('click', this, false);
            });
            this.itemElement.on('mouseenter', () => {
                ActionManager.execute('mouseenter', this, false);
            });
            this.itemElement.on('mouseleave', () => {
                ActionManager.execute('mouseleave', this, false);
            });
        }
    }
    getMainAction() {
        let mainAction = 'no-action';
        if (this.data.actions) {
            this.data.actions.forEach((action) => {
                if (action.event == 'click')
                    mainAction = action.action;
            });
        }
        return mainAction;
    }
    detache() {
        this.itemElement.remove();
    }
}
