import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Game } from "./Game";
import { Item } from './Item';
export class View {
    static draw(viewData) {
        if (!viewData)
            return;
        this.viewData = viewData;
        this.refresh();
    }
    static refresh() {
        $('#view').empty();
        $('#view').css('background-image', 'url(\'' + Game.path + '/' + this.viewData.backgroundImage + '\')');
        this.viewData.items.forEach((itemID) => {
            this.items.push(new Item(Game.itemsData[itemID]));
        });
    }
}
View.items = [];
