import { $ } from 'hanako-ts/dist-legacy/Framework';
export class Timer {
    static init(duration) {
        this.currentTime = this.duration = duration;
    }
    static start() {
        if (this.isStarted)
            return;
        this.intervalID = window.setInterval(() => {
            this.currentTime--;
            this.updateText();
            if (this.currentTime === 0) {
                this.pause();
                $('body').trigger('time-ended');
            }
        }, 1000);
        this.isStarted = true;
        this.updateText();
    }
    static reset() {
        $('#timer div').text('');
        this.currentTime = this.duration;
    }
    static pause() {
        this.isStarted = false;
        window.clearInterval(this.intervalID);
    }
    static formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = time - Math.floor(time / 60) * 60;
        const secondsPadding = seconds < 10 ? '0' : '';
        return minutes + ':' + secondsPadding + seconds;
    }
    static getTime() {
        const minutes = Math.floor(this.currentTime / 60);
        const seconds = this.currentTime - Math.floor(this.currentTime / 60) * 60;
        const secondsPadding = seconds < 10 ? '0' : '';
        return minutes + ':' + secondsPadding + seconds;
    }
    static getElapsedTime(raw = false) {
        return raw ? this.duration - this.currentTime : this.formatTime(this.duration - this.currentTime);
    }
    static setElapsedTime(time) {
        this.currentTime = this.duration - time;
    }
    static updateText() {
        $('#timer div').text(this.formatTime(this.currentTime));
    }
}
