var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import { Game } from '../classes/Game';
import { Modal } from '../helpers/Modal';
import { Toast } from '../helpers/Toast';
export class Home extends Component {
    constructor(isDebugEnabled = false) {
        super('Home', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            Modal.init();
            Toast.init();
            const games = yield $.httpRequest({
                url: '/games/games.json',
                type: 'GET',
                dataType: 'json',
            });
            games.forEach((game) => {
                const gameOptionElement = $.parseHTML('<option value="' + game.path + '">' + game.title + '</option>');
                $('#select-game').append(gameOptionElement);
            });
            $('#input-name').on('keyup', (event) => {
                const name = $('#input-name').val();
                if (name.length > 0) {
                    $('#btn-start').removeClass('disabled');
                }
                else {
                    $('#btn-start').addClass('disabled');
                }
                if (event.key == 'Enter')
                    $('#btn-start').trigger('click');
            });
            $('#btn-start').on('click', (event) => {
                event.preventDefault();
                const name = $('#input-name').val();
                if (name.length === 0) {
                    Modal.show('Erreur', 'Veuillez indiquer votre nom ou celui de votre équipe.');
                    return;
                }
                $('#input-name').val('');
                const gameID = $('#select-game').val();
                sessionStorage.setItem('gameID', gameID);
                Game.init(gameID, name);
                Game.start();
            });
            $('#btn-exit').on('click', (event) => {
                event.preventDefault();
                Modal.show('Attention', 'En quittant la partie, vous perderez votre progression', Modal.createButton('Quitter', 'primary', true, () => {
                    Game.trackAction('quit');
                    Game.stop();
                }));
            });
            $('body').off('click', '.btn-text-panel-next').on('click', '.btn-text-panel-next', (event, button) => {
                event.preventDefault();
                Game.setRoom(button.data('targetRoom'));
            });
            const savedGameID = sessionStorage.getItem('gameID');
            const rawSaveData = sessionStorage.getItem('savedata');
            if (savedGameID && rawSaveData) {
                const saveData = JSON.parse(rawSaveData);
                Game.init(savedGameID, saveData.username);
                yield Game.start(true);
                Game.load(saveData);
            }
            $('#input-name').get(0).focus();
            this.success();
        });
    }
}
